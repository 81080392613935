import React, { useState } from "react";
import "../Style/Header.css";



export default function Header() {
  const [linkLogin, setLinkLogin] = useState(
    "https://gamev2.lsm55goal.com/login"
  );

  const [linkRegister, setLinkRegister] = useState(
    "https://gamev2.lsm55goal.com/register"
  );

  const [activeSection, setActiveSection] = useState('section-1');

  return (
    <>
      <div>
        <div className="header">
          <div className="section-logo">
            <img src="https://fs.cdnxn.com/landing-sbo55/Images/logo-new.png" className="section-head-logo" alt="logo" />
            <div className={`d-flex gap-2 justify-content-center align-items-center ${activeSection === 'section-1' ? 'active-text' : ''}`} onClick={() => { setActiveSection('section-1'); document.getElementById('section-1').scrollIntoView({ behavior: 'smooth' }); }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.3069 7.13261C14.3066 7.1323 14.3062 7.132 14.3059 7.13169L8.867 1.69295C8.63517 1.46102 8.32694 1.33325 7.99908 1.33325C7.67122 1.33325 7.36299 1.46092 7.13106 1.69285L1.69496 7.12885C1.69313 7.13068 1.6913 7.13261 1.68947 7.13444C1.21339 7.61326 1.21421 8.39014 1.69181 8.86774C1.91001 9.08604 2.19819 9.21248 2.50632 9.22571C2.51883 9.22693 2.53145 9.22754 2.54416 9.22754H2.76094V13.2301C2.76094 14.0222 3.40537 14.6666 4.1976 14.6666H6.32549C6.54115 14.6666 6.71612 14.4917 6.71612 14.276V11.1379C6.71612 10.7765 7.0101 10.4825 7.37153 10.4825H8.62662C8.98805 10.4825 9.28204 10.7765 9.28204 11.1379V14.276C9.28204 14.4917 9.4569 14.6666 9.67266 14.6666H11.8006C12.5928 14.6666 13.2372 14.0222 13.2372 13.2301V9.22754H13.4382C13.766 9.22754 14.0742 9.09987 14.3062 8.86794C14.7844 8.38953 14.7846 7.61133 14.3069 7.13261Z" fill="white" />
              </svg>
              หน้าหลัก
            </div>
            <div className={`d-flex gap-2 justify-content-center align-items-center ${activeSection === 'section-3' ? 'active-text' : ''}`} onClick={() => { setActiveSection('section-3'); document.getElementById('section-3').scrollIntoView({ behavior: 'smooth' }); }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.6663 2.60425C13.1143 2.60425 12.6663 3.05158 12.6663 3.60425V12.2709C12.6663 12.8229 13.1143 13.2709 13.6663 13.2709C14.2183 13.2709 14.6663 12.8236 14.6663 12.2709C14.6663 12.2709 14.655 4.08691 14.6663 3.60425C14.6777 3.12158 14.2183 2.60425 13.6663 2.60425ZM11.333 3.93758C8.66634 5.27091 5.33301 5.27091 5.33301 5.27091V9.93758V10.6042V11.2709C5.33301 12.3756 6.22834 13.2709 7.33301 13.2709C8.43767 13.2709 9.33301 12.3756 9.33301 11.2709V11.1876C10.001 11.3729 10.6863 11.6142 11.333 11.9376C11.333 11.2709 11.333 4.60425 11.333 3.93758ZM3.43701 5.27091C2.26168 5.27091 1.33301 6.46158 1.33301 7.93758C1.33301 9.41025 2.27967 10.6042 3.45833 10.6042H3.99967V5.27091H3.43701ZM6.67167 10.6889C7.095 10.7343 7.357 10.7629 7.99833 10.8789L7.99967 11.2709C7.99967 11.6389 7.70101 11.9376 7.33301 11.9376C6.96501 11.9376 6.66634 11.6389 6.66634 11.2709L6.67167 10.6889Z" fill="white" />
              </svg>
              โปรโมชั่น
            </div>
            <div className={`d-flex gap-2 justify-content-center align-items-center ${activeSection === 'section-4' ? 'active-text' : ''}`} onClick={() => { setActiveSection('section-4'); document.getElementById('section-4').scrollIntoView({ behavior: 'smooth' }); }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M7.33337 6.99994C7.33337 7.18447 7.18373 7.3341 6.9992 7.3341C6.81457 7.3341 6.66504 7.18447 6.66504 6.99994C6.66504 6.81531 6.81457 6.66577 6.9992 6.66577C7.18373 6.66577 7.33337 6.81531 7.33337 6.99994Z" fill="white" />
                <path d="M11.6783 6.99981C11.6783 4.4159 9.58372 2.32129 6.99981 2.32129C4.4159 2.32129 2.32129 4.4159 2.32129 6.99981C2.32129 9.58372 4.4159 11.6783 6.99981 11.6783C9.5825 11.6754 11.6754 9.5825 11.6783 6.99981ZM9.00491 7.66815C8.77033 7.6653 8.55488 7.53804 8.43922 7.33398H7.94077C7.83986 7.61708 7.61708 7.83986 7.33398 7.94077V8.43922C7.53814 8.55488 7.6654 8.77033 7.66825 9.00491C7.66825 9.37407 7.36897 9.67324 6.99981 9.67324C6.63065 9.67324 6.33148 9.37407 6.33148 9.00491C6.33433 8.77033 6.46159 8.55488 6.66565 8.43922V7.94077C6.38255 7.83975 6.15977 7.61708 6.05886 7.33398H5.56041C5.44475 7.53804 5.22929 7.6653 4.99472 7.66815C4.62556 7.66815 4.32639 7.36897 4.32639 6.99981C4.32639 6.63065 4.62556 6.33138 4.99472 6.33138C5.22929 6.33423 5.44475 6.46149 5.56041 6.66565H6.05886C6.15977 6.38255 6.38255 6.15977 6.66565 6.05886V5.56041C6.46159 5.44475 6.33433 5.22929 6.33148 4.99472C6.33148 4.62556 6.63065 4.32639 6.99981 4.32639C7.36897 4.32639 7.66825 4.62556 7.66825 4.99472C7.6654 5.22929 7.53814 5.44465 7.33398 5.56041V6.05886C7.61708 6.15977 7.83986 6.38255 7.94077 6.66565H8.43922C8.55488 6.46149 8.77033 6.33423 9.00491 6.33138C9.37407 6.33138 9.67324 6.63065 9.67324 6.99981C9.67324 7.36897 9.37407 7.66815 9.00491 7.66815Z" fill="white" />
                <path d="M1.66977 7.33398H0.333008C0.378275 8.2788 0.625161 9.20308 1.05719 10.0445L2.21654 9.3753C1.89906 8.7385 1.71311 8.04423 1.66977 7.33398Z" fill="white" />
                <path d="M1.66977 6.66564C1.71311 5.95529 1.89916 5.26112 2.21664 4.62422L1.05729 3.95508C0.625263 4.79655 0.378275 5.72082 0.333008 6.66564H1.66977Z" fill="white" />
                <path d="M12.3291 7.33398C12.2858 8.04423 12.0997 8.7385 11.7822 9.3753L12.9416 10.0445C13.3736 9.20308 13.6206 8.2788 13.6659 7.33398H12.3291Z" fill="white" />
                <path d="M9.3756 11.783C8.7387 12.1005 8.04433 12.2866 7.33398 12.3299V13.6667C8.27901 13.6214 9.20338 13.3744 10.0449 12.9423L9.3756 11.783Z" fill="white" />
                <path d="M6.66517 13.6667V12.3299C5.95472 12.2865 5.26035 12.1004 4.62345 11.783L3.9541 12.9422C4.79577 13.3743 5.72014 13.6213 6.66517 13.6667Z" fill="white" />
                <path d="M7.33398 0.333252V1.67002C8.04433 1.71345 8.7387 1.89951 9.3756 2.21699L10.0449 1.05774C9.20338 0.625609 8.27901 0.378519 7.33398 0.333252Z" fill="white" />
                <path d="M4.04367 11.4515C3.45112 11.0567 2.9427 10.5484 2.54791 9.95581L1.3916 10.6233C1.90633 11.417 2.58249 12.0931 3.37615 12.6078L4.04367 11.4515Z" fill="white" />
                <path d="M11.4508 9.95581C11.056 10.5484 10.5476 11.0567 9.95508 11.4515L10.6227 12.6078C11.4164 12.0931 12.0925 11.4169 12.6071 10.6233L11.4508 9.95581Z" fill="white" />
                <path d="M4.62345 2.21699C5.26035 1.89941 5.95472 1.71335 6.66517 1.67002V0.333252C5.72014 0.378519 4.79577 0.625507 3.9541 1.05763L4.62345 2.21699Z" fill="white" />
                <path d="M2.54791 4.04395C2.9427 3.45151 3.45112 2.94319 4.04357 2.54829L3.37605 1.39209C2.58239 1.90672 1.90623 2.58298 1.3916 3.37654L2.54791 4.04395Z" fill="white" />
                <path d="M11.7822 4.62433C12.0997 5.26122 12.2857 5.95539 12.3291 6.66574H13.6659C13.6205 5.72082 13.3736 4.79665 12.9416 3.95508L11.7822 4.62433Z" fill="white" />
                <path d="M9.95508 2.54839C10.5476 2.94319 11.056 3.45151 11.4508 4.04405L12.6071 3.37654C12.0925 2.58298 11.4163 1.90672 10.6227 1.39209L9.95508 2.54839Z" fill="white" />
              </svg>
              ค่ายเกมส์
            </div>
          </div>
          <div className="section-login pc">
            <a href={linkLogin}>
              <div className="login-bg">เข้าสู่ระบบ</div>
            </a>
            <a href={linkRegister}>
              <div className="register-bg">สมัครสมาชิก</div>
            </a>
          </div>
        </div>
        <div className="header mb">
          <div className="section-login">
            <a href={linkLogin}>
              <div className="login-bg">เข้าสู่ระบบ</div>
            </a>
            <a href={linkRegister}>
              <div className="register-bg">สมัครสมาชิก</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
